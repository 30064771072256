import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MatchAccolades from "@/game-deadlock/components/MatchAccolades.jsx";
import MatchTileHits from "@/game-deadlock/components/MatchTileHits.jsx";
import MatchTileScoreboard from "@/game-deadlock/components/MatchTileScoreboard.jsx";
import MatchTileStatistics from "@/game-deadlock/components/MatchTileStatistics.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Match() {
  const {
    parameters: [steamId, matchId],
  } = useRoute();
  const {
    deadlock: { matches, heroes: $heroes, steam: $steam },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const heroes = useEvalState($heroes);
  const $match = matches[matchId];
  const match = useEvalState($match);
  const steam = useMemo(() => $steam[steamId], [$steam, steamId]);
  const player = useMemo(() => getPlayer(steamId, match), [steamId, match]);
  const isWin = useMemo(
    () => !!(match && player && match?.winning_team === player.team),
    [match, player],
  );
  return (
    <SharedMatchLayout
      match={$match}
      title={
        <Title
          level={player?.level}
          title={steam?.name ?? steamId}
          subtitle={heroes[player?.hero_id]?.heroName}
        />
      }
      image={
        Assets.getHeroPortrait(player?.hero_id) || Assets.getDeadlockLogo()
      }
      imageLink={`/deadlock/profile/${steamId}`}
      borderColor={isWin ? "var(--turq)" : "var(--red)"}
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          {[
            match && player && match.winning_team === player.team
              ? t("common:victory", "Victory")
              : t("common:defeat", "Defeat"),
            ,
            match?.duration_s > 0 &&
              t("common:minutes", "{{minutes}} mins", {
                minutes: formatDuration(
                  sanitizeNumber(match.duration_s * 1000),
                  "m:ss",
                ),
              }),
          ]
            .filter(Boolean)
            .map((i) => (
              <React.Fragment key={i}>
                <span className="type-callout color-shade2">{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.start_time && (
            <div className="type-callout color-shade2">
              <TimeAgo
                date={new Date(match.start_time * 1000)}
                className={undefined}
              />
            </div>
          )}
          <DotIcon style={{ color: "var(--shade3)" }} />
          <ShareButton />
        </div>
      }
    >
      <MainColumnsContainer>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <MatchAccolades />
        </div>
        <div className="span-2">
          <MatchTileHits matchId={matchId} steamId={steamId} />
          <MatchTileStatistics matchId={matchId} steamId={steamId} />
          <MatchTileScoreboard matchId={matchId} steamId={steamId} />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

const Title = memo(function Title(props: {
  title: string;
  subtitle?: string;
  level?: number;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div className="flex column">
      <div
        className="h6 shade0"
        style={props.subtitle ? { lineHeight: "var(--sp-7)" } : undefined}
      >
        {props.title}
      </div>
      {!!props.subtitle && (
        <div className="flex align-center gap-2">
          {!!props.level && (
            <div>
              <Badge className="br shade1">
                {t("tft:levelValue", "Level {{level}}", {
                  level: props.level.toLocaleString(language),
                })}
              </Badge>
            </div>
          )}
          <div className="shade1" style={{ fontSize: "var(--sp-4)" }}>
            {props.subtitle}
          </div>
        </div>
      )}
    </div>
  );
});

const Badge = styled("div")`
  font-size: var(--sp-4);
  line-height: var(--sp-4);
  background: var(--shade4);
  padding: var(--sp-1_5) var(--sp-2);
`;

export function meta([steamId]) {
  const name = readState.deadlock.steam[steamId]?.name ?? "Unknown";
  return {
    title: [
      "deadlock:meta.match.title",
      "{{name}}'s Match Stats – Blitz Deadlock",
      { name },
    ],
    description: [
      "deadlock:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
